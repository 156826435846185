import { LoadOneTrust } from './onetrust/loadOneTrust.js';
import { buildPageData } from './page-data/BuildProspectPageData.js';
import { setCookiesIfConsentUpdated, getPageDataFieldsFromCookies  } from './page-data/initCookieLogicManager.js';
import { setupDataLayerAndWrapTagManager } from "./TagManager/LoadScript/setupDataLayerAndWrapTagManager.js";
import { BrowserEnv } from '../utils/browser_env.js';
import { setupGoogleConsent } from './google-consent/setupGoogleConsent.js';
import { type Nil } from '@mcwd/typescript-type-guards';
import { parseBool, parseNumber } from '@mcwd/util-parse-and-convert';
import { Logger } from "../utils/Logger/Logger.js";
import { SiteCookie } from './cookies/SiteCookie.js';

const logger = new Logger({ tags: { category: "SetupProspect" } });
const verboseLogger = logger.withTags({ verboseDebug: "SetupProspect" });

const currentScript = window.document.currentScript as HTMLScriptElement | Nil;
const currentScriptUrlParams = new URLSearchParams(currentScript?.src?.substring(currentScript.src.indexOf("?")));
const readBoolParam = (param: string, defaultVal = true) => (parseBool(currentScriptUrlParams.get(param), { parseFromNumber: true }) ?? defaultVal);

const USE_GOOGLE_CONSENT = readBoolParam("use_google_consent");
const READ_DEFAULTS_FROM_COOKIE = readBoolParam("read_from_cookie");
const USE_ONETRUST = readBoolParam("use_onetrust");
const ENABLE_CONSENT_UPDATE_EVENT = readBoolParam("enable_consent_update_event");

const gcUpdateWaitTime = (parseNumber(currentScriptUrlParams.get("gc_update_wait_time")) ?? -1);
const GC_UPDATE_WAIT_TIME = (gcUpdateWaitTime >= 0 && gcUpdateWaitTime <= 10000)
  ? gcUpdateWaitTime
  : null;

async function updateCookiesIfConsentGiven() {
  if (SiteCookie.PartialEnabled) {
    const updatedCookies = await setCookiesIfConsentUpdated();
    if (updatedCookies.length > 0) {
      const updatedVals = getPageDataFieldsFromCookies(updatedCookies);
      if (Object.keys(updatedVals).length > 0) {
        window.McTagManager.addToDataLayer(updatedVals);
      }
    }
  }
}

async function setupProspect() {
  const pageData = await buildPageData();
  verboseLogger.debug("Setup Prospect: ", {
    USE_GOOGLE_CONSENT,
    READ_DEFAULTS_FROM_COOKIE,
    USE_ONETRUST,
    GC_UPDATE_WAIT_TIME
  });
  if (USE_GOOGLE_CONSENT) {
    const gcSettings = {
      addRegionDefaults: true,
      globalDefaultValue: "granted" as const,
      readDefaultsFromCookie: READ_DEFAULTS_FROM_COOKIE,
      waitForUpdateInMs: GC_UPDATE_WAIT_TIME,
      enableConsentUpdateEvent: ENABLE_CONSENT_UPDATE_EVENT
    };
    verboseLogger.debug("Google Consent Settings: ", gcSettings);
    await setupGoogleConsent(gcSettings);
  }

  await setupDataLayerAndWrapTagManager(pageData);
  if (USE_ONETRUST) {
    const OneTrustSiteId = (BrowserEnv.branch === 'dev' || BrowserEnv.env === 'stage')
      ? "8fad91ac-5f4f-4ac7-84b1-eb4e25ed2848-test"
      : "8fad91ac-5f4f-4ac7-84b1-eb4e25ed2848";
    LoadOneTrust(OneTrustSiteId);

    window.addEventListener("OneTrustGroupsUpdated", () => {
      updateCookiesIfConsentGiven().catch(logger.error);
    });
  }
  Object.keys((window as any).__Mc_ValueGetters as object).forEach((key) => {
    return verboseLogger.debug(key, (window as any).__Mc_ValueGetters[key].value);
  });
}

setupProspect().catch(logger.error);