import { BrowserEnv } from "../../utils/browser_env.js";
import { onetrustVerboseLogger as verboseLogger } from "./oneTrustLogger.js";
import { isString } from "@mcwd/typescript-type-guards";

function oneTrustDebugLog() {
    if (BrowserEnv.branch === "dev") {
        if ("OneTrust" in window) {
            window.OneTrust?.testLog?.();
        }
        else {
            setTimeout(oneTrustDebugLog, 500);
        }
    }
}

function OptanonWrapper() {
    verboseLogger.debug("OptanonWrapper Callback", { OnetrustActiveGroups: window.OnetrustActiveGroups });
    (window as any).originalOnetrustActiveGroups ??= window.OnetrustActiveGroups;
    oneTrustDebugLog();
}

(window as any).OptanonWrapper = OptanonWrapper;

export function LoadOneTrust(dataDomainScript: string) {
    const firstScript = document.getElementsByTagName('script')[0];
    const tag = document.createElement('script');
    tag.async = true;
    tag.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    tag.setAttribute("data-domain-script", dataDomainScript);
    try {
        const lang = (document?.documentElement?.lang ?? "en")?.replace(/(?<=[a-z]{2})-[a-zA-Z]{2}/, "")?.toLowerCase()?.trim();
        if (isString(lang) && /^[a-z]{2}$/.test(lang)) {
            tag.setAttribute("data-language", lang);
        }
    }
    catch {
        console.log("Error: Couldn't correctly set language for OneTrust");
    }
    if (firstScript.parentNode) {
        firstScript.parentNode.insertBefore(tag, firstScript);
    }
    else {
        throw new Error("This should never throw! A parent node of the first script should always be available!"); // At least 1 script is available if this file is included on a page.
    }
}