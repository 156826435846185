import { SiteCountryCode } from "../../types-and-definitions/locale-defs/Locales.js";
import { initAllowedCookiesDependencies, siteCookieLogicManager } from "./initCookieLogicManager.js";
import { initPageDataSettings } from "./initPageDataSettings.js";
import { getMcPageData } from "./McPageData.js";


export async function buildPageData(lang?: SiteCountryCode) {
    const AppState = window.AppState;
    const server = lang ?? window.AppState.GetCurrentLanguageCode();
    const urlPrefixesToRemove = SiteCountryCode.filter(c => (c !== "us"));

    initPageDataSettings({ server, urlPrefixesToRemove });

    await initAllowedCookiesDependencies();
    const PageData = getMcPageData();

    AppState.PageData = PageData;
    
    await siteCookieLogicManager.setPageCookies();

    return PageData;
}